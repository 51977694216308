* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: KenyanCoffee, serif;
}

@font-face {
  font-family: "KenyanCoffee";
  src: url("./fonts/kenyan-coffee-rg.ttf") format("truetype");
  }